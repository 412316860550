@tailwind base;
@tailwind components;
@tailwind utilities;

.correct-answer {
    border: 2px solid green;
}

.wrong-answer{
    border: 2px solid red;
}

.disabled-answer{
    pointer-events: none;
}